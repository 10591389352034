/**
 * Created by chris on 16.11.15.
 */

$(document).ready(function() {
    /*
    PRELOAD LARGE IMAGES
     */
    var detailMap = new Image();
    detailMap.src = window.location.origin + "/img/maps/detail.jpg";

    /*
    ADD VIEWPORT WIDTH TO LOGIN FORM
     */
    var viewportWidth = $(window).width();
    $('<input>').attr('type', 'hidden').attr('value', viewportWidth).attr('name', 'vw').appendTo('main.login form');
});